import { Colors, Heading, TextProps, Text } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'

interface HeadingProps extends TextProps {
  text: string
  firstColor?: keyof Colors
}

const ColoredWordHeading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  text,
  firstColor,
  mb = '24px',
  ...props
}) => {
  const { theme } = useTheme()
  const split = text.split(' ')
  const firstWord = split[0]
  const remainingWords = split.slice(1).join(' ')
  const displayedColor = (theme.colors[firstColor] as string) ?? theme.colors.secondary

  return (
    <Heading  fontSize={['24px !important', null, null, '46px !important']} textAlign={['center', null, null, 'left']} mb={mb} {...props}>
      <span
        css={`
          background: linear-gradient(266deg, #0163E0 -55.06%, #08B2D2 131.43%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: inherit;
          font-weight: 700;
          margin-right: 12px;
        `}
      >
        {firstWord}
      </span>
      {remainingWords}
    </Heading>
  )
}

export default ColoredWordHeading
