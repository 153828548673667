import { Flex, Text, Button, Link, NextLinkFromReactRouter as RouterLink } from '@pancakeswap/uikit'
import CompositeImage, { CompositeImageProps } from '../CompositeImage'
import ColoredWordHeading from '../ColoredWordHeading'
import Image from 'next/image'

interface SalesSectionButton {
  to: string
  text: string
  external: boolean
}

export interface SalesSectionProps {
  headingText: string
  bodyText: string
  reverse: boolean
  primaryButton: SalesSectionButton
  secondaryButton: SalesSectionButton
  images: CompositeImageProps
}

const SalesSection: React.FC<React.PropsWithChildren<SalesSectionProps>> = (props) => {
  const { headingText, bodyText, reverse, primaryButton, secondaryButton, images } = props

  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection={['column-reverse', null, null, reverse ? 'row-reverse' : 'row']}
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          flex="1"
          ml={[null, null, null, reverse && '64px']}
          mr={[null, null, null, !reverse && '64px']}
          alignSelf="center"
        >
          <ColoredWordHeading text={headingText} />
          <Text
            color="textSubtle"
            fontSize={['12px', null, null, '16px']}
            textAlign={['center', null, null, 'left']}
            css={`
              color: #90a3bf;
              // font-size: 16px;
              font-weight: 400;
            `}
            mb="24px"
          >
            {bodyText}
          </Text>
          <Flex gap={'20px'} justifyContent={['center', null, null, 'start']}>
            <Button
              paddingX={['32px', null, null, '60px']}
              paddingY={'13px'}
              height={['56px', null, null ,'56px']}
              rounded
              css={`
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
              `}
              mr="16px"
            >
              <Link
                css={`
                  text-decoration: none !important;
                `}
                external
                href={primaryButton.to}
              >
                <Text color="card" bold fontSize={['16px', null, null, "16px"]}>
                  {primaryButton.text}
                </Text>
              </Link>
            </Button>
            <Link
              fontSize={['16px', null, null, "16px"]}
              css={`
                text-decoration: none !important;
              `}
              external
              href={secondaryButton.to}
            >
              {secondaryButton.text} <Image width={20} height={20} src={'/images/new-landing/arrow-up.svg'}></Image>
            </Link>
          </Flex>
        </Flex>
        <Flex
          maxHeight={['192px', null, null, '400px']}
          height={['192px', null, null, '400px']}
          width={['192px', null, null, '400px']}
          flex={[null, null, null, '1']}
          mb={['24px', null, null, '0']}
        >
          <CompositeImage {...images} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SalesSection
